import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import '../style.scss';
import InformationPopup from './mobile'
import InformationAndPoliciesDesktop from './desktop'
import { useSelector } from 'react-redux';
import QueryParams from 'sites/utils/QueryParams';
import { getInformationAndPoliciesQueryType, getInformationAndPoliciesType } from 'common/components/modules/information-and-policies/sites/data.js';
import { getSiteLink } from 'common/utils/helper';
import { getProtocol, getSiteDomain } from 'common/utils/utils';
import mmsLogoLight from 'assets/images/mymembersite-logo-light.svg'
import mmsLogoDark from 'assets/images/mymembersite-logo-dark.svg'
import { updateContentPageTitleForInformationAndPolicies } from 'sites/utils/utils';

const InformationAndPilices = ({
   showButton,
   isOpen,
   onClose,
   isButton,
   isAboutContent,
   showLinks,
   className,
   showLightLogo,
}) => {
   useEffect(() => {
      const queryParamsType = QueryParams.getParam('information-and-policies');
      const type = getInformationAndPoliciesType(queryParamsType)
      if(type){
         setSecondModalType(decodeURIComponent(type));
      } else if(queryParamsType){
         QueryParams.removeQueryParam('information-and-policies');
      }
   }, []);
   const [secondModalType, setSecondModalType] = useState('');

   const openSecondModal = (type) => {
      updateContentPageTitleForInformationAndPolicies(type)
      const generateQuery = getInformationAndPoliciesQueryType(type);
      if(generateQuery){
         setSecondModalType(type);
         QueryParams.setQueryParam('information-and-policies', encodeURIComponent(generateQuery));
      } else {
         setSecondModalType('')
         if(QueryParams.getParam('information-and-policies')){
            QueryParams.removeQueryParam('information-and-policies');
         }
      }
   }

   const footerPoweredByText = useSelector(state => state.common?.siteInfo?.footer_powered_by_text);
   const isBrandingRemoval = useSelector(state => state.common?.siteInfo?.is_branding_removal);
   const refCode = useSelector(state => state.common?.siteInfo?.referal_code)
   const protocol = getProtocol();
   const domain = getSiteDomain();
   const isAffiliateEnabled = useSelector(state => state.common?.siteInfo?.admin_enabled_affiliate)
   // const siteUuid = useSelector(state => state.common?.siteInfo?.app_id)
   const adminAffId = useSelector(state => state.common?.siteInfo?.unique_number_id)
   const subdomain = useSelector(state => state.common?.siteInfo?.subdomain)
   const legalPages = useSelector(state => state.common?.siteInfo?.legal_pages) || window?.blockedAdditionalData?.additional_info?.site_info?.legal_pages
   const whiteLabel = useSelector(state => state.common?.siteInfo?.white_label)
   const customFooterLinks = useSelector(state => state.common?.siteInfo?.custom_footer_links)
   const affiliateLink =  domain === 'ams.loc' ? `http://localhost:7202/sites/${ adminAffId }-${ subdomain }` : `${ protocol }${ `affiliate.mymember.site` }/sites/${ adminAffId }-${ subdomain }`
   if(!showLinks) {
      return (
         <div className='text-center mt-4 mb-8'>
            {
               !!(footerPoweredByText?.trim()) ? (
                  <span className='text-secondary dark:text-secondary-dark '>{footerPoweredByText} </span>
               ) : (
                  <>
                     {
                        !isBrandingRemoval && (
                           <a className='flex justify-center' target='_blank' rel='noopener noreferrer' href={ getSiteLink() }>
                              {/* {getCapitalizeSiteName()} */}
                              <img src={ window?.site_dark_bg ? mmsLogoDark : mmsLogoLight } className='w-[145px] h-5' alt='mmslogo' />
                           </a>
                        )
                     }
                  </>
               )
            }
         </div>
      )
   }
   return (
      <>
         <div className={ `${ window?.innerWidth < 768 ? `flex mt-4` : `hidden` } justify-center items-center flex-col ${ isButton ? 'mb-14' : 'mb-0' }` }>
            {
               !!(footerPoweredByText?.trim()) ? (
                  <span className='text-secondary dark:text-secondary-dark '>{footerPoweredByText} </span>
               ) : (
                  <>
                     {
                        !isBrandingRemoval && (
                           <img src={ showLightLogo ? mmsLogoLight : (window?.site_dark_bg ? mmsLogoDark : mmsLogoLight) } className='w-[145px] h-5' alt='mmslogo' />
                        )
                     }
                  </>
               )
            }
            <InformationPopup
               showButton={ showButton }
               isOpen={ isOpen }
               onClose={ onClose }
               footerPoweredByText={ footerPoweredByText }
               isBrandingRemoval={ isBrandingRemoval }
               refCode={ refCode }
               secondModalType={ secondModalType }
               openSecondModal={ openSecondModal }
               isAffiliateEnabled={ isAffiliateEnabled }
               affiliateLink={ affiliateLink }
               legalPages={ legalPages }
               whiteLabel={ whiteLabel }
               customFooterLinks={ customFooterLinks }
            />
         </div>
         <div
            className={ cx({
               'md:block hidden w-full mt-auto': true,
               'information-and-polices-is-absalute': isAboutContent,
               [`${ className }`]: !!className,
            }) }
         >
            <InformationAndPoliciesDesktop
               footerPoweredByText={ footerPoweredByText }
               refCode={ refCode }
               secondModalType={ secondModalType }
               openSecondModal={ openSecondModal }
               isAffiliateEnabled={ isAffiliateEnabled }
               affiliateLink={ affiliateLink }
               legalPages={ legalPages }
               customFooterLinks={ customFooterLinks }
               showLightLogo={ showLightLogo }
               isBrandingRemoval={ isBrandingRemoval }
               whiteLabel={ whiteLabel }
            />
         </div>
      </>
   )
}

InformationAndPilices.defaultProps = {
   showButton: true,
   isOpen: false,
   onClose: () => {},
   isButton: false,
   showLinks: true,
   showLightLogo: false,
}

InformationAndPilices.propTypes = {
   showButton: PropTypes.bool,
   isOpen: PropTypes.bool,
   onClose: PropTypes.func,
   isButton: PropTypes.bool,
   isAboutContent: PropTypes.bool,
   showLinks: PropTypes.bool,
   className: PropTypes.string,
   showLightLogo: PropTypes.bool,
}

export default InformationAndPilices;
